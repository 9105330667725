// eslint-disable-next-line
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';

import styles from './style.module.css';

class Button extends Component {
	render() {
		return (
			<button
				onClick={() => {
					this.props.onClickCallback();
				}}
				className={styles.button + ' ' + this.props.className}>
				{this.props.children}{' '}
			</button>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Button);
